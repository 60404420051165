<template>
  <div>
    <Header />

    <!--Container-->
    <div class="container w-full md:max-w-6xl mx-auto pt-20">
      <div
        class="w-full px-4 md:px-6 text-xl  leading-normal"
        style="font-family:Georgia,serif;"
      >
        <!--Title-->
        <div class="font-sans">
          <h1
            class="font-bold font-sans break-normal  pt-6 pb-2 text-5xl md:text-5xl text-center mb-8"
          >
            About Us
          </h1>
        </div>
      </div>
    </div>
    <!--/container-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  components: {
    Header
  },
  mounted() {
    // Add background image in body tag
    let body = document.getElementsByTagName("body")[0];
    body.style.background = `url(${require("@/assets/img/landing/banner.png")}) repeat`;
    body.style.color = "#fff";
  }
};
</script>
